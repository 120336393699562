import { DecodedValueMap, StringParam, withDefault } from 'use-query-params';
import ArrayParam from '../utils/ArrayParam';
import NumberParam from '../utils/NumberParam';
import bookingParams from './bookingParams';

export const searchFilterParams = {
    andFilters: ArrayParam,
    orFilters: ArrayParam,
    tripType: ArrayParam,
    specialId: withDefault(StringParam, undefined),
    minPrice: NumberParam,
    maxPrice: NumberParam,
    minimalMinPrice: NumberParam,
    maximalMaxPrice: NumberParam,
};

export const searchViewParams = {
    offset: withDefault(NumberParam, undefined),
    order: StringParam,
    bbTop: NumberParam,
    bbBottom: NumberParam,
    bbLeft: NumberParam,
    bbRight: NumberParam,
};

export const searchBarParams = {
    ...bookingParams,
    accommodationType: withDefault(StringParam, undefined, true),
    period: StringParam,
};

const searchParams = {
    ...searchFilterParams,
    ...searchViewParams,
    ...searchBarParams,
};

export default searchParams;

export type SearchParams = Partial<DecodedValueMap<typeof searchParams>>;
