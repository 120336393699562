import { createEnumParam, StringParam, withDefault } from 'use-query-params';
import DateParam from '../utils/DateParam';
import NumberParam from '../utils/NumberParam';

const bookingParams = {
    type: withDefault(createEnumParam(['static', 'flexible-random', 'flexible-weekends', 'coming-month']), undefined),
    amountOfNights: withDefault(NumberParam, undefined),
    flexibleMonth: withDefault(StringParam, undefined),
    arrivalDate: DateParam,
    departureDate: DateParam,
    amountAdults: withDefault(NumberParam, undefined),
    amountChildren: withDefault(NumberParam, undefined),
    amountPets: withDefault(NumberParam, undefined),
    amountBabies: withDefault(NumberParam, undefined),
    amountYouths: withDefault(NumberParam, undefined),
};

export default bookingParams;
