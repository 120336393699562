import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import styled from 'styled-components';

const UspBody = styled(Body)`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary[50]};
    display: flex;
    align-items: center;

    svg {
        stroke-width: 3px;
        width: 23px;
        height: 23px;
        margin-right: ${({ theme }) => theme.spacing['30_Small']};
    }
`;

export default UspBody;
