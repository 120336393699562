import ArrowDown from '@oberoninternal/travelbase-ds/components/figure/ArrowDown';
import { Box, BoxProps } from '@rebass/grid';
import React, { createContext, FC, useContext } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useAccordion } from './Accordion';

const AccordionItemContext = createContext<{ index: number } | undefined>(undefined);
const useAccordionItem = () => {
    const ctx = useContext(AccordionItemContext);
    const { openIndex, setOpenIndex } = useAccordion();

    if (ctx === undefined) {
        throw new Error('useAccordionItem must be used within an AccordionItem');
    }

    const isOpen = openIndex === ctx.index;

    return { ...ctx, isOpen, onToggle: () => (!isOpen ? setOpenIndex(ctx.index) : setOpenIndex(null)) };
};

interface AccordionItemProps {
    index: number;
}

const AccordionItem: FC<React.PropsWithChildren<AccordionItemProps>> = ({ index, ...props }) => (
    <AccordionItemContext.Provider value={{ index }}>
        <StyledAccordionItem {...props} />
    </AccordionItemContext.Provider>
);

export default AccordionItem;

export const AccordionItemHeader: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { isOpen, onToggle } = useAccordionItem();

    return (
        <Heading onClick={onToggle}>
            <Box>{children}</Box>
            <StyledArrowDown rotate={isOpen} />
        </Heading>
    );
};

export const AccordionItemContent: FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
    const { isOpen } = useAccordionItem();
    const styles = useSpring({ maxHeight: isOpen ? '150px' : '0px' });

    return (
        <StyledAccordionItemDescription style={styles}>
            <Box mt={4} className="accordion-item-innerdiv" {...props}>
                {children}
            </Box>
        </StyledAccordionItemDescription>
    );
};

const StyledAccordionItem = styled.li`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    padding: 2.4rem 0;
`;

const StyledAccordionItemDescription = styled(animated.div)`
    overflow: hidden;
`;

const Heading = styled.button`
    padding: 0;
    color: ${({ theme }) => theme.colors.secondary['50']};
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing['30_Small']};
    width: 100%;
`;

const StyledArrowDown = styled(ArrowDown)<{ rotate: boolean }>`
    transition: transform 0.2s ease-in-out;
    transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
    color: ${({ theme }) => theme.colors.neutral['100']};
    margin-left: auto;
`;
