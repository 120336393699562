import { useEffect } from 'react';

const useAnalyticsContentCategory = (contentCategory?: string) => {
    useEffect(() => {
        if (!contentCategory) {
            return;
        }
        setTimeout(() => {
            window.dataLayer = window.dataLayer ?? [];
            window.dataLayer.push({
                content_category: contentCategory,
            });
        });
    }, [contentCategory]);
};

export default useAnalyticsContentCategory;
