import addDays from 'date-fns/addDays';
import getDay from 'date-fns/getDay';
import addWeeks from 'date-fns/addWeeks';

const getUpcomingDay = (day: number, weekMargin = 0) => {
    const currentDate = addWeeks(new Date(), weekMargin);
    const current = getDay(currentDate);
    const diff = day - current;
    return addDays(currentDate, diff === -1 ? 6 : diff); // adjust when the currentDate is saturday
};

export default getUpcomingDay;
