import mergeWith from 'lodash.mergewith';
import omit from 'lodash.omit';
import { SearchBarValues } from '../components/SearchBox';
import { SearchParams, searchViewParams } from '../constants/searchParams';
import getDateParamsForPeriodType, { PeriodType } from './getDateParamsForPeriodType';

/** convert the filters object ```{ someFilter: true }``` to ```['someFilter']``` */
export const convertToQueryFilters = (filters: Record<string, boolean>): string[] => filters && Object.keys(filters);

/** convert the filters ```['someFilter', 'anotherFilter']``` to the formik-ready ```{ someFilter: true, anotherFilter: true }``` */
export const convertToFormikFilters = (filters: string[]): Record<string, boolean> =>
    filters.reduce((acc, next) => ({ ...acc, [next]: true }), {});

export const orFilterRegExp = /_(.*)\./;
export const matchHandle = (property: string) => orFilterRegExp.exec(property)?.[0];

/**  AND filters need to be formatted like: [{ properties: ['filterA'] }, { properties: ['filterB'] }] */
export const convertToAndFilters = (filters: string[]) => filters.map(filter => ({ properties: [filter] }));

/**  OR filters need to be formatted like: [{ properties: ['filterA', 'filterB', 'filterC'] }] */
export const convertToOrFilters = (filters: string[]) =>
    filters.reduce<Array<{ properties: string[] }>>((acc, next) => {
        // first check if there's already an array of certain OR filters
        const orFilterIndex = acc.findIndex(({ properties }) => {
            const [property] = properties;
            const handle = matchHandle(property);
            const nextHandle = matchHandle(next);
            return handle === nextHandle;
        });

        // when we have a match we want to extend the current properties for that OR filter
        if (orFilterIndex !== -1) {
            acc[orFilterIndex] = { properties: [...acc[orFilterIndex].properties, next] };
            return acc;
        }

        return [...acc, { properties: [next] }];
    }, []);

export const getAccommodationFilters = (type: string) =>
    type.length
        ? type
              .split(' ')
              .filter(value => value !== 'all')
              .map(value => value)
        : [];

export const convertToFilters = (...args: Array<[string[], 'OR' | 'AND']>) =>
    args.reduce<Array<{ properties: string[] }>>((acc, [filters, type]) => {
        if (type === 'OR') {
            return [...acc, ...convertToOrFilters(filters)];
        }
        return [...acc, ...convertToAndFilters(filters)];
    }, []);

const customizer = (obj: object, src: object) => {
    if (Array.isArray(obj) && Array.isArray(src)) {
        return [...new Set(obj.concat(src))];
    }

    return undefined;
};

export const mergeParams = (
    startParams: SearchParams,
    initialStorageVals: SearchBarValues | undefined,
    params: SearchParams,
    bakedInParams: SearchParams,
    bakedInFilterProperty: string | undefined
) => {
    const paramsActive = Object.keys(omit(params, Object.keys(searchViewParams))).length > 0;
    return mergeWith(
        {},
        // only take into account the startParams if there aren't any relevant params set
        paramsActive ? {} : startParams,
        {
            ...initialStorageVals?.booking,
            ...(initialStorageVals?.accommodationType && { accommodationType: initialStorageVals?.accommodationType }),
            ...getDateParamsForPeriodType(
                (startParams?.period ?? params?.period ?? bakedInParams?.period) as PeriodType
            ),
        },
        params,
        bakedInParams,
        bakedInFilterProperty ? { andFilters: [bakedInFilterProperty] } : {},
        customizer
    );
};
