import Modal, { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTenantContext } from '../context/TenantContext';
import Accordion from './designsystem/Accordion';
import AccordionItem, { AccordionItemContent, AccordionItemHeader } from './designsystem/AccordionItem';
import UspBody from './UspBody';

interface UspsModalProps extends ModalProps {
    visibleIndex: number;
}

const UspsModal = ({ visibleIndex, ...props }: UspsModalProps) => {
    const { brandConfig } = useTenantContext();
    const usps = brandConfig.usps ?? [];
    if (!usps.length) return null;
    return (
        <Modal variant="regular" {...props}>
            <ModalHeader>
                <FormattedMessage
                    values={{ count: usps.length }}
                    defaultMessage="{count} redenen om bij ons te boeken!"
                />
            </ModalHeader>

            <Accordion defaultIndex={visibleIndex} mt={4}>
                {usps.map(({ title, description }, index) => (
                    <AccordionItem key={index} index={index}>
                        <AccordionItemHeader>
                            <UspBody variant="large">{title}</UspBody>
                        </AccordionItemHeader>
                        <AccordionItemContent pl={5} py={3}>
                            <Body>{description}</Body>
                        </AccordionItemContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </Modal>
    );
};

export default UspsModal;
