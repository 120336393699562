import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import parseToDateString from '../constants/parseToDateString';
import getUpcomingDay from './getUpcomingDay';

export type PeriodType = 'nextWeekend' | 'nextWeek' | 'comingMonth';

const getDateParamsForPeriodType = (periodType?: PeriodType) => {
    const nextFriday = getUpcomingDay(5, 1);

    switch (periodType) {
        case 'nextWeek': {
            return {
                arrivalDate: parseToDateString(nextFriday),
                departureDate: parseToDateString(addWeeks(nextFriday, 1)),
            };
        }
        case 'nextWeekend': {
            return {
                arrivalDate: parseToDateString(nextFriday),
                departureDate: parseToDateString(addDays(nextFriday, 2)),
            };
        }
        case 'comingMonth': {
            return {
                arrivalDate: undefined,
                departureDate: undefined,
            };
        }
        default:
            return undefined;
    }
};

export default getDateParamsForPeriodType;
