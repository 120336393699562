import { Box, BoxProps } from '@rebass/grid';
import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

const AccordionContext = createContext<
    { openIndex: number | null; setOpenIndex: (index: number | null) => void } | undefined
>(undefined);

export const useAccordion = () => {
    const ctx = useContext(AccordionContext);

    if (ctx === undefined) {
        throw new Error('useAccordion must be used within an Accordion component');
    }

    return ctx;
};

interface AccordionProps extends BoxProps {
    defaultIndex?: number;
}

const Accordion: FC<React.PropsWithChildren<AccordionProps>> = ({ children, defaultIndex = null, ...props }) => {
    const [openIndex, setOpenIndex] = useState(defaultIndex);
    const value = useMemo(() => ({ openIndex, setOpenIndex }), [openIndex]);

    return (
        <AccordionContext.Provider value={value}>
            <StyledAccordion {...props}>{children}</StyledAccordion>
        </AccordionContext.Provider>
    );
};

export default Accordion;

const StyledAccordion = styled(Box).attrs({ as: 'ul' })`
    padding: 0;
`;
